* {
    user-select: none;
    -webkit-user-select: none;
    color: #343434;
}

body {
    justify-content: center;
    font-family: Arial, Helvetica, sans-serif;
    touch-action: none;
    margin: 0;
    align-items: center;
}

#wrapper {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
}

#info-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
}

.padding {
    flex-grow: 1;
    min-height: 0;
    position: relative;
    max-width: 500px;
    display: flex;
    margin: auto;
}

#header-btn-container {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    margin-top: 10px;
}

#footer-btn-container {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    margin-bottom: 10px;
}

body, #wrapper {
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    overflow: hidden;
}

#scoreWrapper {
    width: 80%;
    max-width: 500px;
    position: relative;
    display: flex;
    margin: 10px auto;
    height: auto;
    justify-content: space-evenly;
}

.score-label {
    font-size: 1.3em;
}

#seedWrapper {
    width: 80%;
    position: relative;
    display: flex;
    margin: 10px auto;
    height: auto;
    justify-content: center;
    gap: 20px;
}

#current-word-container {
    position: relative;
    height: 36px;
    display: flex;
    margin-bottom: 20px;
    padding: 10px 20px;
    background: #faff6d;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition-duration: 500ms;
    transition-property: opacity transform;
    transition-timing-function: ease-out;
}

#current-word-container.valid {
    background: rgb(149, 255, 135);
}

#current-word-label {
    text-align: center;
    font-size: 30px;
}

#letter-container {
    position: relative;
    width: 90%;
    height: 100%;;
    flex-grow: 1;
    max-width: 500px;
    max-height: 500px;
    margin-bottom: 20px;
    overscroll-behavior: contain;
}

.tile {
    position: absolute;
    display: flex;
    aspect-ratio: 1;
    border-color: rgb(0, 0, 0);
    border-width: 2px;
    border-style: solid;
    border-radius: 6px;
    background: antiquewhite;
    font-size: 2em;
    text-align: center;
    width: 50px;
    height: 50px;
    left: 0px;
    bottom: 0px;
    transition-duration: 500ms;
    transition-property: transform;
    transition-timing-function: ease-out;
    overscroll-behavior: contain;
}

.tileCentre {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overscroll-behavior: contain;
}

.letter-wrapper {
    position: relative;
    width: 100%;
    margin: auto;
    pointer-events: none;
}

.tile.potential {
    background: rgb(250, 255, 109);
    transform: scale(0.9);
}

.tile.valid {
    background: rgb(149, 255, 135);
    transform: scale(0.9);
}

#solutionLabel {
    text-align: center;
}

.button {
    position: relative;
    cursor: pointer;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    pointer-events: none;
}

#undoBtn, #resetBtn, #mute-btn, #viewStatsBtn, #leaderboardBtn {
    margin-bottom: 10px;
    background: white;
    border-color: #343434;
    border-width: 2px;
    border-style: solid;
}

#undoBtn {
    opacity: 0.5;
}

.modal-background {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #0000007a;
    display: none;
    justify-content: center;
}

.modal-content {
    width: 95%;
    max-width: 400px;
    max-height: 100%;
    background: white;
    border-radius: 10px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    position: relative;

    transition-duration: 500ms;
    transition-property: transform;
    transition-timing-function: ease-in-out;
}

.modal-header {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: space-evenly;
}

#intro-header {
    margin-bottom: 10px;
}

#intro-video {
    border: dotted;
    margin: 10px 0px;
    flex-grow: 1;
}

.intro-subtitle {
    margin-bottom: 5px;
    font-size: 1.0em;
}

.stats-title {
    font-size: 1.2em;
    font-weight: bold;
}

.stats-sub-title {
    margin-top: 5px;
    font-size: 1.2em;
    font-style: italic;
}

.stats-sub-title {
    margin-top: 5px;
    font-size: 1.2em;
    font-style: italic;
}

#attempts-container {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: space-evenly;
}

.attempt-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.found-words-container {
    padding: 10px 20px;
    background: antiquewhite;
    border-radius: 10px;
}

.stats-found-word {
    margin-top: 5px;
    font-weight: bold;
    text-align: center;
}

.leaderboard-text {
    margin-top: 5px;
    font-size: 1.2em;
    font-style: italic;
    text-align: center;
}

#post-to-leaderboard-container {
    width: 80%;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

#post-input-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
}

.modal-footer {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    gap: 20px;
}

.divider {
    position: relative;
    height: 1px;
    width: 80%;
    margin-top: 20px;
    background-color: #838383;
}

.wide-button {
    display: flex;
    justify-content: space-around;
    height: 40px;
    border-radius: 24px;
    cursor: pointer;
    align-items: center;
}

#retry-btn {
    background-color: #3b6969;
}

#close-stats-btn, #close-leaderboard-btn {
    background-color: #3b6969;
}

#share-btn {
    background-color: #6688ff;
}

#post-btn {
    background-color: #6688ff;
    opacity: 0.5;
}

#dismiss-instruction-btn {
    background-color: #6688ff;
    padding-right: 20px;
}

.wide-btn-text {
    color: white;
    margin-left: 20px;
}

.wide-btn-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 40px;
    height: 40px;
}

#undo-btn-text, #reset-btn-text, #mute-btn-text {
    color: #343434;
}

#retry-btn-img {
    background-image: url(./img/retry-icon.svg);
}

#share-btn-img {
    background-image: url(./img/share-icon.svg);
    margin-right: 5px;
}

#close-btn-img {
    background-image: url(./img/close-stats-icon.svg);
    margin-right: 5px;
}

#post-btn-img {
    background-image: url(./img/leaderboard-icon.svg);
}

#stats-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 20px;
    width: 90%;
}

.stats-pair {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 0px
}

.stats-score-label {
    font-size: 2.4em;
}

.stats-heading-label {
    font-size: 0.8em;
    text-align: center;
}

#turns-graph {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 150px;
    min-height: 150px;
    justify-content: space-evenly;
    align-items: flex-end;
    margin-top: 20px;
    margin-bottom: 20px;
}

.turn-graph-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    gap: 5px;
    justify-content: end;
}

.graph-bar-background {
    height: 100%;
    width: 20px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 5px;
}

.graph-bar {
    background: #6688ff;
    height: 50%;
    width: 100%;
    min-height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.graph-bar-key {
    width: 100%;
    text-align: center;
}

.graph-bar-label {
    width: 100%;
    text-align: center;
    color: white;
}

.chart-title {
    margin-top: 20px;
    font-size: 1em;
}

#blocking-view {
    position: fixed;
    width: 100%;
    height: 100%;
}

#end-text-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes background-wave {
    0% {
        transform: translateX(0%);
    }

    /* 50% {
        background: linear-gradient(#80ffc8, #80f4ff);
    } */

    100% {
        transform: translateX(-50%);
    }
}

#end-text-wrapper {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

#end-text {
    position: relative;
    font-size: 2em;
    color: #303030;
    margin: 16px 20px;
}

#end-text-background {
    position: absolute;
    width: 200%;
    height: 100%;
    background: linear-gradient(to right, #68f1fd, #62ff7f, #68f1fd, #62ff7f);
    animation: background-wave 1000ms linear 0s infinite;
}

#leaderboard {
    margin-top: 10px;
    width: 100%;
}

.lc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 5px 0px;
}

.lc-basics-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin: 0px 20px 0px 20px;
    width: 150px;
    min-width: 0;
}

.lc-icons-container {
    display: flex;
    gap: 3px;
    height: 36px;
}

.lc-position {
    font-size: 30px;
    font-weight: bold;
}

.lc-name {
    font-size: 20px;
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    min-width: 0;
}

.lc-score {
    font-size: 20px;
    font-weight: bold;
}

.lc-chevron {
    margin: 0px 20px 0px 10px;
    height: 24px;
}

/* @media screen and (max-width: 500px), screen and  (max-height: 500px) {
    #current-word-container {
        display: none;
    }
} */

@media screen and (orientation: landscape) and (max-height: 550px) {
    #wrapper {
        flex-direction: row;
    }

    #info-container {
        width: auto;
        flex-direction: column;
        align-items: center;
        min-width: 220px;
    }

    #scoreWrapper {
        width: 100%;
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }

    #seedWrapper {
        width: 100%;
        flex-direction: column;
        align-items: center;
        gap: 0;
    }

    #letter-container {
        margin-right: 20px;
        margin-top: 20px;
        width: 100%;
        height: 90%;
    }
}

@media (prefers-color-scheme: dark) {
    * {
        color: white;
    }

    body {
        background-color: #121212;
    }

    .tile {
        border-color: #372206;
        background: #52473a;
    }

    #current-word-container {
        background: rgb(136, 121, 9);
    }

    #current-word-container.valid {
        background: rgb(2, 102, 0);
    }

    .tile.potential {
        background: rgb(136, 121, 9);
    }

    .tile.valid {
        background: rgb(2, 102, 0);
    }

    .button {
        background-color: white;
    }

    #undoBtn, #resetBtn {
        background: #121212;
        border-color: white;
    }

    .modal-content {
        background: #121212;
    }

    .found-words-container {
        background: rgb(48, 38, 24);
    }

    #retry-btn {
        background-color: #3b6969;
    }
    
    #share-btn {
        background-color: #1e284d;
    }
    
    #dismiss-instruction-btn {
        background-color: #1e284d;
    }

    .wide-btn-text {
        color: white;
    }

    #undo-btn-text, #reset-btn-text {
        color: white;
    }

    .stroked-svg {
        stroke: white;
    }

    .filled-svg {
        fill: white;
    }
}


.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3b6969; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  input {
    padding: 8px;
    border: 1px solid #343434;
    border-radius: 6px;
    background-color: #f8f8f8;
  }